import styled, { css } from 'styled-components';

import LogoSvg from 'vectors/logo.svg';

export const LoginFormContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    margin: auto;
    margin-top: 10%;
    padding: 48px 24px;
    max-width: 720px;
    justify-content: center;
    border-radius: 8px;

    & > * {
      width: 100%;
      text-align: center;
    }
  `,
);

export const StyledLogoSvg = styled(LogoSvg)`
  width: 280px;
  margin: auto;
`;
