import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { validation } from 'services';
import { Input } from 'common/form';
import { Button } from 'common/interaction';
import { Heading } from 'common/typography';

import { LoginFormContainer, StyledLogoSvg } from './styled';

export const LoginForm: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <LoginFormContainer>
      <StyledLogoSvg />
      <Heading as="h4">Log in voor toegang</Heading>
      <Input
        {...register('username', { ...validation.required })}
        error={errors?.username}
        placeholder="Gebruikersnaam"
      />
      <Input
        {...register('password', { ...validation.required })}
        error={errors?.password}
        placeholder="Wachtwoord"
        type="password"
      />
      <Button type="submit">Inloggen</Button>
    </LoginFormContainer>
  );
};
