import * as i from 'types';
import { toast } from 'react-toastify';

import { useRouter } from 'hooks';
import { isServer } from 'services';
import { FormContainer } from 'common/form';
import { LoginForm } from 'modules/loginForm';

const FORM_ID = 'login';
const USERNAME = 'HCS';
const PASSWORD = 'DImetGG2023!';

const Login: i.NextPageComponent = () => {
  const router = useRouter();

  const onSubmit = (loginValues: LoginFormValues) => {
    if (loginValues.username === USERNAME && loginValues.password === PASSWORD) {
      const cookieAge = 14 * 24 * 60 * 60; // 14 days is the requested "authentication" duration.
      document.cookie = `authenticated=true; max-age=${cookieAge};`;
      !isServer && router.back();
    } else {
      toast.error('E-mailadres en wachtwoord combinatie onjuist. Probeer het opnieuw.');
    }
  };

  return (
    <>
      <FormContainer
        {...{
          onSubmit,
          id: FORM_ID,
        }}
      >
        {() => <LoginForm />}
      </FormContainer>
    </>
  );
};

type LoginFormValues = {
  username: string;
  password: string;
};

export default Login;
